import store from "@/state/store";
import {priceHelper} from "./price-helper";

export const productsHelper = {
    profitMarginFormatter,
    priceHighFormatter,
    priceLowFormatter,
    profitFormatter,
    ecatProfitFormatter,
    logisticsFormatter,
    parsePrice,
    parseText,
    getOr
};

function profitMarginFormatter(value, key, item, profitMargin = 0) {
    if (item.profitMargin > 0.0) {
        profitMargin = item.profitMargin;
    }

    if (item.productProfitMargin > 0.0) {
        profitMargin = item.productProfitMargin;
    }

    if (item.warehouseProfitMargin > 0.0) {
        profitMargin = item.warehouseProfitMargin
    }

    return profitMargin + "%"
}

function priceHighFormatter(value, key, item) {
    if (item.priceMarginTaxIncluded) {
        return priceHelper.format(item.priceMarginTaxIncluded, store.getters["market/currentCurrency"])
    }

    return priceHelper.format(item.priceHigh, store.getters["market/currentCurrency"])
}

function priceLowFormatter(value, key, item, taxRate) {
    taxRate = 1 + (taxRate / 100.0)

    let price = item.priceHigh
    if (item.priceMarginTaxIncluded) {
        price = item.priceMarginTaxIncluded
    } else if (item.productPriceHigh) {
        price = item.productPriceHigh
    }

    return priceHelper.format((price / taxRate), store.getters["market/currentCurrency"])
}

function profitFormatter(value, key, item, taxRate, profitMargin = 0) {
    let nettoPriceWith1Percent = item.priceWholesaleHigh;
    if (item.productPriceWholesaleHigh) {
        nettoPriceWith1Percent = item.productPriceWholesaleHigh
    }

    if (item.profitMargin > 0.0) {
        profitMargin = item.profitMargin;
    }

    if (item.warehouseProfitMargin > 0.0) {
        profitMargin = item.warehouseProfitMargin
    }

    let operatorProfitDivision = store.getters["parameters/getOperatorProfitDivision"];

    let profitMarginPrice = (profitMargin / 100) * nettoPriceWith1Percent;
    let bruttoPrice = (1.0 + (taxRate / 100.0)) * (nettoPriceWith1Percent + profitMarginPrice);

    let profit = (operatorProfitDivision / 100.0) * (profitMarginPrice - (0.02 * bruttoPrice));

    return priceHelper.format(profit, store.getters["market/currentCurrency"])
}

function ecatProfitFormatter(value, key, item, taxRate, profitMargin = 0) {
    let nettoPriceWith1Percent = item.priceWholesaleHigh;
    if (item.productPriceWholesaleHigh) {
        nettoPriceWith1Percent = item.productPriceWholesaleHigh
    }

    if (item.profitMargin > 0.0) {
        profitMargin = item.profitMargin;
    }

    if (item.warehouseProfitMargin > 0.0) {
        profitMargin = item.warehouseProfitMargin
    }

    let profitDivision = store.getters["parameters/getEcatProfitDivision"];
    let profitMarginPrice = (profitMargin / 100) * nettoPriceWith1Percent;
    let bruttoPrice = (1.0 + (taxRate / 100.0)) * (nettoPriceWith1Percent + profitMarginPrice);
    let profit = (profitDivision / 100.0) * (profitMarginPrice - (0.02 * bruttoPrice));

    return priceHelper.format(profit, store.getters["market/currentCurrency"])
}

function logisticsFormatter(value, key, item, taxRate, profitMargin = 0) {
    let nettoPriceWith1Percent = item.priceWholesaleHigh;
    if (item.productPriceWholesaleHigh) {
        nettoPriceWith1Percent = item.productPriceWholesaleHigh
    }

    if (item.profitMargin > 0.0) {
        profitMargin = item.profitMargin;
    }

    if (item.warehouseProfitMargin > 0.0) {
        profitMargin = item.warehouseProfitMargin
    }

    let profitMarginPrice = (profitMargin / 100) * nettoPriceWith1Percent;
    let bruttoPrice = (1.0 + (taxRate / 100.0)) * (nettoPriceWith1Percent + profitMarginPrice);
    let twoPercent = (0.02 * bruttoPrice);

    return priceHelper.format(twoPercent, store.getters["market/currentCurrency"])
}

function parsePrice(value) {
    if (!value) {
        value = 0.0
    }

    return priceHelper.format(value, store.getters["market/currentCurrency"])
}

function parseText(text, rejectText = "-", value = " ") {
    if (!text) {
        return rejectText
    }

    return text + value
}

function getOr(text, rejectText = "-", value) {
    if (!text) {
        return rejectText
    }

    return value
}